<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    :items="data"
    item-text="text"
    item-value="value"
  />
</template>

<script>
  import { LANGUAGE } from "@/helpers/enums";

  export default {
    inheritAttrs: false,
    name: "LanguagePicker",
    props: {
      value: String
    },
    data: vm => ({
      selected: null,
      data: [
        { text: vm.$t("language.tr"), value: LANGUAGE.tr },
        { text: vm.$t("language.en"), value: LANGUAGE.en },
        { text: vm.$t("language.de"), value: LANGUAGE.de }
      ]
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    }
  };
</script>

<style scoped></style>
